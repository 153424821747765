body,
html {
	font-size: $baseFont;
	line-height: normal;
	font-weight: 400;
	background-color: $primaryBg;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: $primaryFont;
}

ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
	margin: 0;
	line-height: 140%;
	font-weight: 400;
}

p,
a,
li {
	font-weight: 300;
}

.navbar {
	box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
	background-color: #fff;
	padding: 10px 40px;
    @media(max-width:767px) {
        padding: 10px 0px;
    
    }

	.nav-pills {
		display: block;

		@media screen and (min-width: 1200px) {
			display: flex;
			align-items: center;
		}
	}

	.nav-item {
		margin-bottom: 20px;
		position: relative;

		@media screen and (min-width: 1200px) {
			margin-bottom: 0;
		}

		&+.nav-item {
			@media screen and (min-width: 1200px) {
				margin-left: 21px;
				margin-top: 0;
				position: relative;
			}
		}

		.dropdown-menu {
			border: 0;
			box-shadow: 0px 8px 12px #ccc;
			padding-top: 5px;

			.dropdown-item {
				font-weight: 400;
				font-size: 14px;
				line-height: 160%;
				color: #000000;
				border-bottom: 1px solid #F0F1F4;
				padding: 8px 16px;
				background: transparent;

				&.active {
					background-color: #fcc65c;
					font-weight: 700;
					color: #4d535f;
					
				}
			}
		}

		&:hover .dropdown-menu{
			display: block;
		}

	}

	.login {
		a {

			border-radius: 8px;
			background: $bgColor;
			padding: 12px 36px;
			color: #000;
			font-weight: 600;
			transition: 0.5s all ease-in-out;

			&:hover {
				background: #cca454;
				color: #fff;
			}
		}
	}

	.navbar-brand {
		padding: 0;

		img {
			display: block;
			width: 140px;



		}
	}

	.nav-link {
		padding: 10px 20px;
		color: #404753;
		text-align: center;
		font-size: 12px;
		font-family: $primaryFont;
		font-style: normal;
		font-weight: 600;
		line-height: 140%;

		@media screen and (min-width: 1200px) {
			padding: 11px 8px;
		}

		&.active {
			color: $bgColor;
			position: relative;
			background-color: transparent;
			font-weight: 700;
		}

		&:hover {
			color: $bgColor;
		}

	}
}
