.footer-section {
	background: #404753;
	padding: 40px 0px;
	@media (max-width: 767px) {
		padding: 20px 0px;
	}

	.footer-logo {
        h3 {
            color: #FFF;
            font-size: 28px !important;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            padding: 30px 0px 6px;
        }
		p {
			color: #959595;
            font-size: 16px;

            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            span {
                color: #fff;
                text-transform: uppercase;
            }
		}
	}

	.footer-widget {
        h3 {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
        }
        ul {
            li {
                a {
                    color: #adacac;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 150%;
                    padding: 4px 0px;
                    display: block;
                }
            }
        }
        .form-group {
            position: relative;
		.form-control {
            border-radius: 8px;
            border: 1px solid #959595;
            background: transparent;
            margin: 16px 0px 24px;
        }
        .btn {
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 0px 8px 8px 0px;
                border: 1px solid #2988FE;
            background: #2988FE;
            color: #fff;

        }
    }



		.footer-social {
			padding-top: 10px;

			a {
				display: block;
                line-height: 38px;
                margin-right: 10px;
                color: #ffffff;
                font-size: 20px;
                transition: 0.5s all ease-in-out;
                font-weight: 500;
				transition: 0.5s all ease-in-out;
				.fa {
                    margin-right: 10px;
                }

			}
		}
	}
    .bottom-nav {
        padding: 16px 0px;
        border-top: 1px solid #626262;
        margin-top: 20px;
        ul {
            display: flex;
            justify-content: end;
            gap: 60px;
            li {
                a {
                    color: #A8A8A8;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }
            }
        }
    }
}

.copyright-area {
	background: #F9F9FD;
	padding: 20px 0px;
	border-top: 2px solid #D4D6E8;

	p {
		font-weight: 400;
		font-size: 14px;
		line-height: 160%;

		font-feature-settings: 'salt' on, 'liga' off;

		color: #111111;
	}
}