@import './utils/variables';
@import './components/header';
@import './components/footer';


.banner-area {
	padding: 80px 0px;

	@media(max-width:767px) {
		padding: 40px 0px;
	}

	.banner-right {
		img {
			display: block;
			margin: 0 auto;
			max-width: 100%;
			object-fit: contain;
			height: 239px;
		}
	}

	.banner-content {
		h1 {
			color: #404753;
			font-size: 26px;
			font-family: $primaryFont;
			font-style: normal;
			font-weight: 700;
			line-height: 140%;

			@media(max-width:767px) {
				font-size: 32px;
			}

			span {
				font-weight: normal;
				display: block;
				font-size: 18px;
				font-family: $primaryFont;

				@media(max-width:767px) {
					font-size: 24px;
				}
			}
		}

		p {
			color: #404753;
			font-size: 18px;
			font-family: Inter;
			font-style: normal;
			font-weight: 400;
			line-height: 200%;

			+p {
				margin-top: 16px;
			}
		}

		a {
			padding: 12px 32px;
			border-radius: 47.399px;
			border: 2px solid $bgColor;
			background: #FFF;
			color: #404753;
			text-align: center;
			font-size: 15.29px;
			font-family: $primaryFont;
			font-style: normal;
			font-weight: 400;
			line-height: 200%;
			letter-spacing: 0.459px;
			text-transform: uppercase;
			margin-top: 40px;
			display: inline-block;
			transition: 0.5s all ease-in-out;

			@media(max-width:767px) {
				padding: 6px 14px;
			}

			&:hover {
				background: $bgColor;

				color: #fff;
			}
		}
	}

	.banner-right {
		img {
			@media(max-width:767px) {
				width: 100%;
			}
		}
	}
}

.about-us {
	position: relative;

	&::before {
		content: '';
		position: absolute;
		background-image: url(../img/bg-bar.png);
		top: 30%;
		left: 0;
		background-size: 100%;
		height: 100%;
		width: 100%;
		background-repeat: no-repeat;
		opacity: 0.3;
	}
}

.heading-title {
	h3 {
		color: #404753;
		font-size: 32px;
		text-align: center;
		font-family: $primaryFont;
		font-style: normal;
		font-weight: 600;
		line-height: 200%;

		@media(max-width:767px) {
			font-size: 26px;
			line-height: 130%;
		}
	}

	p {
		color: #404753;
		text-align: center;
		font-size: 24px;
		font-family: $primaryFont;
		font-style: normal;
		font-weight: 400;
		line-height: 200%;

		@media(max-width:767px) {
			font-size: 16px;
		}
	}
}

.mockup-setion {
	padding: 20px 0px;
	position: relative;

	p {
		color: #262626;
		font-size: 18px;
		font-family: Inter;
		font-style: normal;
		font-weight: 400;
		line-height: 200%;

		+p {
			margin-top: 16px;
		}
	}

	.nav-pills {
		margin: 10px 0px 40px;
		display: flex;
		justify-content: center;

		.nav-link {
			&.active {
				border-radius: 8px;
				background: $bgColor;
			}

			border-radius: 8px;
			border: 1px solid #CACACA;
			color: #404753;
			text-align: center;
			font-size: 14px;
			font-family: $primaryFont;
			font-style: normal;
			font-weight: 500;
			line-height: 150%;
			margin: 0px 12px;

			@media(max-width:767px) {
				margin-bottom: 10px;
				font-size: 16px;
			}

			&:hover {
				background: $bgColor;
			}
		}
	}
	h2 {
		font-size: 22px;
		font-weight: 700;
		margin: 20px 0px 12px;
	}
	.card {
		border-radius: 20px;
		border: 1px solid #D2D2D2;
		background: #FFF;
		box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
		text-align: center;
		height: 100%;
		margin-bottom: 0;

		@media(max-width:767px) {
			margin-bottom: 20px;
		}

		.card-body {
			display: flex;
			flex-direction: column;
			align-items: center;
			.card-title {
				color: #404753;
				font-size: 16px;
				font-family: $primaryFont;
				font-style: normal;
				font-weight: 600;
				line-height: 160%;
				margin: 0;
				padding: 10px 10px;
				// min-height: 84px;
			}

			.card-text {
				color: #404753;
				font-size: 14px;
				font-family: $primaryFont;
				flex-grow: 1;
				font-style: normal;
				font-weight: 400;
				line-height: 170%;
			}

			.btn {
				border-radius: 22px;
				border: 1px solid #0669FF;
				background: #FFF;
				color: #0669FF;
				font-size: 18px;
				font-family: $primaryFont;
				font-style: normal;
				font-weight: 600;
				line-height: 184%;
				padding: 4px 30px;
				margin: 30px 0px 10px;
				transition: 0.5s all ease-in-out;

				&:hover {
					background: #0669FF;
					color: #fff;
				}
			}
		}
	}

	.see-all {
		display: flex;
		justify-content: center;
		margin: 30px 0px;

		a {
			border-radius: 8px;
			border: 1px solid $bgColor;
			color: #404753;
			font-size: 16px;
			font-family: $primaryFont;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			padding: 10px 16px;
			transition: 0.5s all ease-in-out;

			&:hover {
				background: $bgColor;
			}
		}
	}
}

.exams-setion {
	padding: 0px 0px 40px;

	.exams-card {
		display: flex;
		flex-wrap: wrap;
		gap: 30px;
		margin: 16px 0px;

		@media(max-width:767px) {
			justify-content: center;
		}

		li {
			width: calc((100% - 120px) / 5);
			border-radius: 20px;
			border: 1px solid #E7E7E7;
			background: #EEF2F7;
			box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);

			@media(max-width:767px) {
				width: calc((100% - 31px) / 2);
			}

			a {
				padding: 55px 40px;
				color: #404753;
				font-size: 36px;
				font-family: $primaryFont;
				font-style: normal;
				font-weight: 300;
				line-height: 200%;
				display: flex;
				justify-content: center;
				align-items: center;

				@media(max-width:767px) {
					padding: 30px 30px;
					font-size: 26px;
				}
			}
		}
	}
}

.blogs-setion {
	.blog-card {
		display: flex;
		column-gap: 20px;
		width: 1040px;
		// justify-content: center;
		margin: 20px auto;

		@media(max-width:767px) {
			width: auto;
			justify-content: center;
			margin: 20px auto;
			flex-direction: column;
		}

		.image {
			img {
				height: 100px;
				width: 150px;
    			border-radius: 10px;
			}
		}

		.blog-content {
			h3 {
				color: #404753;
				font-size: 20px;
				font-family: $primaryFont;
				font-style: normal;
				font-weight: 500;
				line-height: 160%;
				text-align: left;
			}

			p {
				color: #404753;
				font-size: 16px;
				font-family: $primaryFont;
				font-style: normal;
				font-weight: 300;
				line-height: 140%;
				text-align: left;
				text-align: left;
			}

			a {
				border-radius: 4px;
				background: $bgColor;
				color: #404753;
				margin-top: 10px;
				font-size: 13px;
				font-family: $primaryFont;
				font-style: normal;
				font-weight: 400;
				line-height: 21px;
				padding: 6px 14px;
				display: inline-block;
			}
		}

	}
}

.refer-friend {
	width: 680px;
	margin: 50px auto;
	text-align: center;

	@media(max-width:767px) {
		width: auto;
		padding: 10px;
		margin: 0px 0px 10px;
	}

	.form-group {
		margin-top: 30px;

		.form-control {
			border-radius: 12px;
			border: 1px solid #CDCDCD;
			background: #F8F8F8;
			padding: 12px;

			&::placeholder {
				color: #C4C4C4;
			}
		}

		.send-btn {
			border-radius: 8px;
			background: $bgColor;
			color: #404753;
			font-size: 20px;
			font-family: $primaryFont;
			font-style: normal;
			font-weight: 600;
			line-height: 200%;
			padding: 6px 20px;
			padding: 2px 50px;
			border: 0;
		}
	}
}

.table-responsive {
	thead {
		background: #404753;
		color: #fff;

		th {
			padding: 14px 20px;
			color: #FFF;
			font-size: 16px;
			font-family: $primaryFont;
			font-style: normal;
			font-weight: 600;
			line-height: 140%;
		}
	}

	tbody {
		tr {
			border-bottom: 0;
			border-color: #fff;

			td {
				color: #404753 !important;
				font-size: 16px;
				font-family: $primaryFont;
				font-style: normal;
				font-weight: 400;
				line-height: 140%;
				padding: 14px 20px;
			}
		}
	}
}

.year-papers {
	padding: 30px 0px;

	.nav-title {
		background: #404753;
		color: #FFF;
		font-size: 16px;
		font-family: $primaryFont;
		font-style: normal;
		font-weight: 600;
		line-height: 140%;
		padding: 8px 20px;
	}

	.nav-pills {
		margin: 10px 0px 40px;
		display: flex;
		justify-content: center;

		.nav-item {
			.nav-link {
				&.active {
					color: #404753;
					background-color: transparent;
					// border-right: 4px solid #333;
					font-weight: 600;
					position: relative;

					&::after {
						content: '';
						position: absolute;
						right: -2px;
						top: 10px;
						width: 4px;
						height: 30px;
						background-color: #000;
					}
				}

				color: #B5B5B5;
				font-size: 16px;
				font-family: $primaryFont;
				font-style: normal;
				font-weight: 400;
				line-height: 200%;
				border: 0;
				border-radius: 0;
				border-right: 2px solid #BCBCBC;
				text-align: left;
				margin: 0;
				padding: 4px 20px;

				&:hover {
					background: transparent;
					color: #404753;
				}

			}
		}
	}

	.blog-items {

		li {
			display: flex;
			justify-content: space-between;

			padding: 10px 20px;

			&:nth-of-type(odd) {
				background: #F5F5F5;
			}

			p {
				color: #404753;
				font-size: 16px;
				font-family: $primaryFont;
				font-style: normal;
				font-weight: 400;
				line-height: 28px;

			}

			.y-title {
				width: 340px;
			}

			a {
				border-radius: 4px;
				background: $bgColor;
				color: #404753;
				text-align: center;
				font-size: 13px;
				font-family: $primaryFont;
				font-style: normal;
				font-weight: 400;
				line-height: 21px;
				padding: 6px 16px;
			}

			.no-bg {
				color: #0669FF;
				text-align: center;
				font-size: 16px;
				font-family: $primaryFont;
				font-style: normal;
				font-weight: 500;
				line-height: 140%;
				background-color: transparent;
			}
		}
	}
}

.about-noti {
	width: 900px;
	margin: auto;

	.table-responsive {
		width: 780px;
		margin: auto;
	}
}

.qad {
	padding: 40px 0px;

	h3 {
		color: #404753;
		font-size: 20px;
		font-family: Inter;
		font-style: normal;
		font-weight: 700;
		line-height: 200%;
	}

	ul {
		li {
			list-style: disc;
			color: #404753;
			font-size: 20px;
			font-family: Inter;
			font-style: normal;
			font-weight: 400;
			line-height: 200%;
			padding: 2px 0px;
			margin-left: 28px;
		}
	}
}

.banner-title {
	text-align: center;
	padding: 40px 0px 120px;

	h1 {
		color: #404753;
		text-align: center;
		font-size: 30px;
		font-family: Inter;
		font-style: normal;
		font-weight: 300;
		line-height: 220%;

		span {
			font-size: 50px;
			font-weight: 700;
			display: block;
		}
	}

	P {
		color: #024CBC;
		text-align: center;
		font-size: 40px;
		font-family: $secondaryFont;
		font-style: normal;
		font-weight: 400;
		line-height: 100%;
		text-transform: uppercase;
		padding: 30px 0px;
	}
}

.articles-sec {
	.nav-pills {
		.nav-link {
			&.active {
				color: #404753;
			}

			border: none;
			color: #6F7C84;
			font-weight: 400;

		}
	}

	.blog-items {
		li {
			padding: 20px 4px;
			border-bottom: 2px solid #F0F0F0;
			align-items: self-start;

			&:nth-of-type(odd) {
				background: transparent;
			}

			.left-sec {
				span {
					border-radius: 6px;
					background: #EEF2F7;
					color: #516E8B;
					text-align: center;
					font-size: 12px;
					font-family: Inter;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
					padding: 3px 12px;
					margin-top: 10px;
					display: inline-block;
				}
			}
		}
	}
}

.weekly-section {
	padding: 40px 0px;

	.videos {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		padding: 20px 0px;
		row-gap: 40px;

		li {
			width: calc((100% - 40px) / 3);

			img {
				width: 100%;
			}

			p {
				color: #404753;
				font-size: 20px;
				font-family: Inter;
				font-style: normal;
				font-weight: 500;
				line-height: 28px;
				padding: 10px 0px;
			}

			span {
				color: #A3A3A3;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
			}
		}
	}

	.see-all {
		display: flex;
		justify-content: center;
		margin: 30px 0px;

		a {
			border-radius: 8px;
			border: 1px solid #FCC65C;
			color: #404753;
			font-size: 18px;
			font-family: "Inter", sans-serif;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			padding: 10px 16px;
			transition: 0.5s all ease-in-out;
		}
	}
}

.interview-sections {
	padding: 40px 0px;
	position: relative;

	.questions {
		display: flex;
		justify-content: space-between;
		padding: 20px 0px;
		border-bottom: 2px solid #F0F0F0;

		.left-side {
			width: 90%;

			h4 {
				color: #404753;
				font-size: 24px;
				font-family: Inter;
				font-style: normal;
				font-weight: 600;
				line-height: 200%;
			}

			p {
				color: #404753;
				font-size: 24px;
				font-family: Inter;
				font-style: normal;
				font-weight: 400;
				line-height: 200%;
				margin-left: 22px;
			}
		}

		.right-side {
			a {
				border-radius: 4px;
				background: #FCC65C;
				color: #404753;
				text-align: center;
				font-size: 13px;
				font-family: "Inter", sans-serif;
				font-style: normal;
				font-weight: 400;
				line-height: 21px;
				padding: 8px 14px;
			}
		}
	}
}

.blog-details {
	padding: 20px;
    box-shadow: 0px 3px 20px #dbd6d66e;
    // margin: 20px 0px;


	img {
		width: 100%;
		height: 400px;
		border-radius: 10px;
	}
	h1 {
		font-size: 30px;
		color: #000;
		font-weight: 600;
		padding: 12px 0px;
	}
	span {
		font-size: 16px;
		display: block;
		color: #767272;
	}
	.share-media {
		display: flex;
		gap: 20px;
		padding: 20px 0px;
		align-items: center;
		border-bottom: 1px solid #6f6e6e;
		margin-bottom: 20px;
		li {
			a {
				width: 40px;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				font-size: 16px;
				color: #fff;
				&.email {
					background: #848484;
				}
				&.twitter {
					background: #1da1f2;
				}
				&.fb {
					background: #3b5998;
				}
				&.linkedin {
					background: #0077b5;
				}
			}

		}
		.share {
			font-size: 16px;
			color: #767272;
			font-weight: 400;
		}
	}
	.content-details {
		p {
			font-size: 18px;
			color: #333;
			+ {
				margin-top: 20px;
			}
		}
	}
	.tgs-d {
		margin-top: 20px;
		h3 {
			font-size: 20px;
			font-weight: 700;
		}
		ul {
			display: flex;
			gap: 12px;
			margin-top: 10px;
			li {
				background: #555454;
				font-size: 14px;
				color: #fff;
				border-radius: 5px;
				padding: 4px 6px;
			}
		}
	}
}